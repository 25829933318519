<template>
  <div>
    <q-chip
      square
      v-if="value"
      :color="getStatusColor(value) ? getStatusColor(value) : 'primary'"
      text-color="white"
    >
      {{ getString() }}
    </q-chip>
  </div>
</template>

<script>
export default {
  name: "ProReportChipTdField",
  props: {
    value: null,
    list: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    getStatusColor(val) {
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].value === val) {
          return this.list[i].color;
        }
      }
    },
    getString() {
      if (!this.value) return "";

      let filter = this.list.filter((b) => b.value === this.value);

      if (filter.length > 0) return this.$t(filter[0].label);
      return this.value;
    },
  },
};
</script>
